<template>
  <b-overlay :show="isLoading">
    <div class="card card-statistics rounded-lg">
      <div class="card-header">
        <h4 class="card-title">
          สรุปยอดผู้เล่น
        </h4>
        <p class="card-text font-small-2 mr-25 mb-0">
          อัพเดทล่าสุด: {{ now }}
        </p>
      </div>
      <div class="card-body statistics-body">
        <div class="row height-match">
          <div class="col-sm-6 col-xl-6 mb-2">
            <div class="media">
              <div class="media-aside align-self-start">
                <span
                  class="b-avatar badge-light-success rounded-circle"
                  style="width: 48px; height: 48px"
                ><span
                  class="b-avatar-custom"
                >
                  <i class="far fa-user-friends fa-2x" /> </span></span>
              </div>
              <div class="media-body my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{
                    data
                      ? data.todayRegisterMembers.total.toLocaleString("en-US", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      })
                      : 0
                  }}
                </h4>
                <p class="card-text font-small-3 mb-0 text-muted">
                  สมาชิกใหม่ (วันนี้)
                </p>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-xl-6">
            <div class="media">
              <div class="media-aside align-self-start">
                <span
                  class="b-avatar badge-light-warning rounded-circle"
                  style="width: 48px; height: 48px"
                ><span
                  class="b-avatar-custom"
                >
                  <i class="far fa-user-friends fa-2x" /> </span></span>
              </div>
              <div class="media-body my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{
                    data
                      ? data.totalNoDepositMembers.total.toLocaleString("en-US", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      })
                      : 0
                  }}
                </h4>
                <p class="card-text font-small-3 mb-0 text-muted">
                  สมาชิกที่ยังไม่ฝากเงิน
                </p>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-xl-6">
            <div class="media">
              <div class="media-aside align-self-start">
                <span
                  class="b-avatar badge-light-dark rounded-circle"
                  style="width: 48px; height: 48px"
                ><span
                  class="b-avatar-custom"
                >
                  <i class="far fa-user-friends fa-2x" /> </span></span>
              </div>
              <div class="media-body my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{
                    data
                      ? data.todayNoDepMembers.total.toLocaleString("en-US", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      })
                      : 0
                  }}
                </h4>
                <p class="card-text font-small-3 mb-0 text-muted">
                  สมาชิกใหม่ที่ยังไม่ฝาก (วันนี้)
                </p>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-xl-6 mb-2">
            <div class="media">
              <div class="media-aside align-self-start">
                <span
                  class="b-avatar badge-light-danger rounded-circle"
                  style="width: 48px; height: 48px"
                ><span
                  class="b-avatar-custom"
                ><i class="far fa-user-friends fa-2x" /></span></span>
              </div>
              <div class="media-body my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{
                    data
                      ? data.totalDepositMembers.total.toLocaleString("en-US", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      })
                      : 0
                  }}
                </h4>
                <p class="card-text font-small-3 mb-0 text-muted">
                  สมาชิกที่ฝากเงินแล้ว
                </p>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-xl-6 mb-2">
            <div class="media">
              <div class="media-aside align-self-start">
                <span
                  class="b-avatar badge-light-primary rounded-circle"
                  style="width: 48px; height: 48px"
                ><span
                  class="b-avatar-custom"
                >
                  <i class="far fa-user-friends fa-2x" /> </span></span>
              </div>
              <div class="media-body my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{
                    data
                      ? data.depfirst_count.toLocaleString("en-US", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      })
                      : 0
                  }}
                </h4>
                <p class="card-text font-small-3 mb-0 text-muted">
                  สมาชิกใหม่ฝากแรก (วันนี้)
                </p>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-xl-6">
            <div class="media">
              <div class="media-aside align-self-start">
                <span
                  class="b-avatar badge-light-info rounded-circle"
                  style="width: 48px; height: 48px"
                ><span
                  class="b-avatar-custom"
                ><i class="far fa-user-friends fa-2x" /></span></span>
              </div>
              <div class="media-body my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{
                    data
                      ? data.totalRegisterMembers.total.toLocaleString("en-US", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      })
                      : 0
                  }}
                </h4>
                <p class="card-text font-small-3 mb-0 text-muted">
                  สมาชิกทั้งหมด
                </p>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-xl-6">
            <div class="media">
              <div class="media-aside align-self-start">
                <span
                  class="b-avatar rounded-circle"
                  style="width: 48px; height: 48px; background-color: #f8bbd0"
                >
                  <span class="b-avatar-custom">
                    <i class="far fa-user-friends fa-2x" />
                  </span>
                </span>
              </div>
              <div class="media-body my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{
                    data
                      ? data.activeNew.toLocaleString("en-US", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      })
                      : 0
                  }}
                </h4>
                <p class="card-text font-small-3 mb-0 text-muted">
                  ยอด Active (วันนี้)
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import moment from 'moment-timezone'
import {
  BSpinner, BRow, BCol, BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BSpinner, BRow, BCol, BOverlay,
  },
  data() {
    return {
      isLoading: false,
      data: null,
      now: moment.tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm'),
    }
  },
  mounted() {
    this.getData('USERS')
  },
  methods: {
    async getData(type) {
      this.isLoading = true
      try {
        const { data } = await this.$http.get(`/dashboard?type=${type}`)

        this.data = data
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
