<template>
  <b-overlay
    :show="isLoading"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-title>
        <div class="pt-2 px-2">
          รายการคืนยอดเสีย
        </div>
      </b-card-title>
      <b-card-body>
        <div class="row no-gutters">
          <div class="d-flex">
            <div class="d-flex align-start">
              <div class="btn-disable">
                สกุลเงิน
              </div>
            </div>

            <b-form-select
              v-model="currency"
              :options="currencyOPs"
              placeholder="เลือกสกุลเงิน"
            />
          </div>
          <div class="d-flex">
            <div class="d-flex align-start">
              <div class="btn-disable">
                วันที่ (จาก)
              </div>
            </div>

            <flat-pickr
              v-model="dateStart"
              class="form-control"
              placeholder="เลือกวันที่"
            />
          </div>
          <div class="d-flex">
            <div class="d-flex align-start">
              <div class="btn-disable">
                วันที่ (ถึง)
              </div>
            </div>

            <flat-pickr
              v-model="dateEnd"
              class="form-control"
              placeholder="เลือกวันที่"
            />
          </div>
          <div class="d-flex">
            <div class="d-flex align-start">
              <div class="btn-disable">
                ค้นหา ยูส, เบอร์โทร
              </div>
            </div>

            <b-form-input
              v-model="term"
              class="form-control"
              placeholder="ค้นหา"
              @keyup.enter="getData()"
            />
          </div>
          <button
            class="btn btn-primary ml-1"
            @click="getData()"
          >
            ค้นหา
          </button>
        </div>
      </b-card-body>

      <b-table
        striped
        hover
        small
        responsive
        show-empty
        class="mt-2 position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
      >

        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' :
              'ไม่พบข้อมูล' }}
          </div>
        </template>
        <template #cell(bank)="{ item }">
          <img
            :src="`/bankIcon/${item.user_bankimg}`"
            alt=""
            height="25"
            width="25"
            class="rounded-circle"
          >
        </template>
        <template #cell(cashback_amount)="{ item }">
          {{ parseFloat(item.cashback_amount).toFixed(2) }}
        </template>
        <!-- <template #cell(created_at)="{ item }">
          {{ item.created_at | dateFormat }}
        </template> -->
        <template #cell(winlost)="{ item }">
          {{ currency === 'THB' ? item.winlost.toFixed(2) : item.winlost_kr.toFixed(2) }}
        </template>
        <template #cell(turnover)="{ item }">
          {{ currency === 'THB' ? item.turnover.toFixed(2) : item.turnover_kr.toFixed(2) }}
        </template>
        <template #cell(cashback_amount)="{ item }">
          {{ currency === 'THB' ? item.cashback_amount + ' ' + currency : item.cashback_amount_kr + ' ' + currency }}
        </template>
        <template #cell(commission_amount)="{ item }">
          {{ currency === 'THB' ? item.commission_amount + ' ' + currency : item.commission_amount_kr + ' ' + currency }}
        </template>
      </b-table>
      <div
        style="
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
  color: #333;
  "
      >
        <div style="text-align: left; font-weight: bold; font-size: 1.0em; margin-left: 43%;">
          <span>ราม</span>
          <span
            v-if="currency === 'THB'"
            :style="{ marginLeft: '25px', color: sum_cash_th >= 0 ? 'green' : 'red' }"
          >
            {{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(sum_cash_th) }}
          </span>
          <span
            v-else
            :style="{ marginLeft: '25px', color: sum_cash_kr >= 0 ? 'green' : 'red' }"
          >
            {{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(sum_cash_kr) }}
          </span>
        </div>

        <div style="text-align: right; font-weight: bold; font-size: 1.0em; margin-right: 29%;">
          <span>ราม</span>
          <span
            v-if="currency === 'THB'"
            :style="{ marginLeft: '25px', color: sum_com_th >= 0 ? 'green' : 'red' }"
          >
            {{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(sum_com_th) }}
          </span>
          <span
            v-else
            :style="{ marginLeft: '25px', color: sum_com_kr >= 0 ? 'green' : 'red' }"
          >
            {{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(sum_com_kr) }}
          </span>
        </div>
      </div>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="getData()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="page"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getData()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BFormInput, BFormGroup, BFormSelect, BPagination, BTable, BCard, BCardTitle, BCardBody, BOverlay, BIconController, BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment-timezone'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BFormSelect,
    BPagination,
    BTable,
    BCard,
    BCardTitle,
    BCardBody,
    BOverlay,
    BIconController,
    BButton,
    flatPickr,
  },
  filters: {
    dateFormat(val) {
      return moment(val).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm')
    },
  },
  data: () => ({
    perPage: 20,
    pageOptions: [20, 50, 100, 150, 200],
    totalRows: 1,
    page: 1,
    fields: [
      { key: 'member_id', label: 'ยูสเซอร์เนม' },
      { key: 'tel', label: 'เบอร์โทร' },
      {
        key: 'winlost',
        label: 'Win/Lost',
        sortable: true,
        // formatter: value => {
        // // eslint-disable-next-line no-restricted-globals
        //   if (!value || isNaN(value)) {
        //     return '0.00'
        //   }
        //   return new Intl.NumberFormat('en-US').format(value)
        // },
      },
      {
        key: 'turnover',
        label: 'turnover',
        sortable: true,
      },
      { key: 'cashback_percent', label: '% จากยอดเสีย', sortable: true },
      { key: 'cashback_amount', label: 'รายได้จากยอดเสีย', sortable: true },
      { key: 'commission_percent', label: '% จากคอมมิชชั่น', sortable: true },
      { key: 'commission_amount', label: 'รายได้จากคอมมิชชั่น', sortable: true },
      { key: 'type', label: 'ประเภท' },
      {
        key: 'created_at',
        label: 'วันที่',
        formatter: value => (value
          ? moment(value)
            .subtract(1, 'days')
            .tz('Asia/Bangkok')
            .format('YYYY-MM-DD')
          : ''),
      },
    ],
    items: [],
    isLoading: false,
    term: '',
    dateStart: moment().tz('Asia/Bangkok').clone().subtract(1, 'days')
      .format('YYYY-MM-DD'),
    dateEnd: moment().subtract(1, 'days').tz('Asia/Bangkok').format('YYYY-MM-DD'),
    URLs: null,
    currency: 'THB',
    currencyOPs: ['THB', 'KRW'],
    sum_cash_th: 0,
    sum_cash_kr: 0,
    sum_com_th: 0,
    sum_com_kr: 0,
  }),
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.isLoading = true
      try {
        const { data } = await this.$http.get('/bonus/cashback/getCashback', {
          params: {
            page: this.page,
            perPage: this.perPage,
            dateStart: this.dateStart,
            dateEnd: this.dateEnd,
            term: this.term,
          },
        })

        if (data && data.data) {
          const totals = data.data.reduce(
            (acc, item) => {
              acc.sum_cash_th += item.cashback_amount || 0
              acc.sum_cash_kr += item.cashback_amount_kr || 0
              acc.sum_com_th += item.commission_amount || 0
              acc.sum_com_kr += item.commission_amount_kr || 0
              return acc
            },
            {
              sum_cash_th: 0,
              sum_cash_kr: 0,
              sum_com_th: 0,
              sum_com_kr: 0,
            },
          )

          this.sum_cash_th = totals.sum_cash_th
          this.sum_cash_kr = totals.sum_cash_kr
          this.sum_com_th = totals.sum_com_th
          this.sum_com_kr = totals.sum_com_kr
          this.totalRows = data.total || 0
          this.items = data.data || []
        }
      } catch (error) {
        console.error('Error fetching cashback data:', error)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
